<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              v-if="authUser.isAdmin"
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.product"
                dense
                :items="['oem', 'fleet']"
                item-text="name"
                item-value="id"
                :disabled="type == 'edit'"
                :label="$t('select_product_*')"
                :rules="[rules.required]"
                @change="productChange"
              />
            </v-col>
            <v-col
              v-if="authUser.isAdmin"
              cols="6"
              sm="6"
            >
              <v-autocomplete
                v-model="options.tenant"
                dense
                :loading="tenantLoading"
                :items="tenantsByProduct"
                item-text="name"
                item-value="id"
                :label="$t('select_tenant_*')"
                :rules="[rules.required]"
                @change="onTenantChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.terminal"
                dense
                :loading="terminalLoading"
                :items="(options.tenant) ? terminalList : []"
                item-value="id"
                item-text="name"
                :label="$t('terminal_*')"
                :rules="[rules.required]"
                @change="onTerminalChange"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.fuel_tank"
                :loading="fuelLoading"
                dense
                :items="options.terminal ? fuelList : []"
                item-value="id"
                item-text="name"
                :label="$t('fuel_tank') + ' *'"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.make"
                dense
                :label="$t('ev_make_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.model"
                dense
                :label="$t('ev_model_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.model_year"
                dense
                type="number"
                :min="getStartingYear()"
                :max="getCurrentYear()"
                :label="$t('year_*')"
                :rules="[rules.required, rules.min_year(), rules.max_year()]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.serial_number"
                dense
                :label="$t('serial_no_*')"
                :rules="[rules.required, rules.number]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.operational_status"
                dense
                :items="statusOptions"
                item-value="id"
                item-text="name"
                :label="$t('status_*')"
                :rules="[rules.required_options]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.iot_device"
                dense
                :items="authUser.isAdmin
                  ? options.tenant
                    ? iotDevicesList
                    : []
                  : iotDevicesList
                "
                :loading="isLoadingIotDevices"
                item-value="id"
                item-text="imei"
                clearable
                :label="$t('iot_device') + ' *'"
                :rules="[rules.required]"
              />
              <!-- :rules="[rules.required]" -->
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.dispenser_identifier"
                dense
                :label="$t('dispenser_identifier_*')"
                :rules="[rules.required]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <div class="d-flex align-center justify-space-between">
                <div class="text-h3">
                  {{ $t("Nozzles") }}
                </div>
                <v-btn
                  color="primary"
                  small
                  class="addButton"
                  @click="addNozzles"
                >
                  +
                </v-btn>
              </div>
              <div
                v-for="(row, i) in options.nozzles"
                :key="i"
                class="d-flex justify-space-between align-center mt-2"
              >
                <v-row class="d-flex align-center">
                  <v-col cols="4">
                    <v-text-field
                      v-model="options.nozzles[i].nozzle_number"
                      dense
                      :label="$t('nozzle_number_*')"
                      :rules="[rules.required, rules.number]"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="options.nozzles[i].flow_rate"
                      dense
                      :label="$t('flow_rate') +' (L) *'"
                      :rules="[rules.required, rules.number]"
                    />
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="options.nozzles[i].operational_status"
                      dense
                      :items="statusOptions"
                      item-value="id"
                      item-text="name"
                      :label="$t('status_*')"
                      :rules="[rules.required_options]"
                    />
                  </v-col>
                  <!-- <v-col cols="2">
                    <v-select
                      v-model="options.nozzles[i].is_active"
                      dense
                      :items="activeOptions"
                      item-value="id"
                      item-text="name"
                      :label="$t('is_active') + ' *'"
                      :rules="[rules.required_options]"
                    />
                  </v-col> -->
                  <v-col
                    v-if="i !== 0 "
                    cols="1"
                    class="d-flex justify-end"
                  >
                    <v-icon
                      class="deleteIcon"
                      @click="deleteRow(i,row)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small>{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {
        nozzles: [
          {
            nozzle_number: null,
            flow_rate: null,
            // is_active: null,
            operational_status: null,
          },
        ],
      },
      product: null,
      tenantOEM: [],
      deleted_ids:[],
      statusOptions: [
        {
          id: 1,
          name: "active",
        },
        {
          id: 0,
          name: "inactive",
        },
      ],
      activeOptions: [
        {
          id: 1,
          name: "yes",
        },
        {
          id: 0,
          name:"no",
        },
      ],
      typeOptions: [
        {
          id: "primary",
          name: "Primary",
        },
        {
          id: "secondary",
          name: "Secondary",
        },
      ],
      fuelTypeOptions: [
        {
          id: "petrol",
          name: "Petrol",
        },
        {
          id: "diesel",
          name: "Diesel",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      tenantLoading: "tenantsManagement/getIsLoading",
      tenantsList: "tenantsManagement/getTenantOptions",
      tenantsOEM: "tenantsManagement/getTenantsOEM",
      tenantsFLEET: "tenantsManagement/getTenantsFLEET",
      fuelList: "fuelDispenser/getDropDownFuel",
      fuelLoading: "fuelDispenser/getDropdownloadingFuel",
      terminalList: "fuelDispenser/getTerminalDropDown",
      terminalLoading: "fuelDispenser/getDropdownloading",
      isLoadingIotDevices: "iotDevices/getIsLoadingIotDevices",
      iotDevicesList: "iotDevices/getIotDevicesList",
    }),
    tenantsByProduct() {
      if (this.options?.product) {
        if (this.options.product == "oem") {
          return this.tenantsOEM;
        } else {
          return this.tenantsFLEET;
        }
      } else {
        return [];
      }
    },
    title() {
      if (this.type == "create") {
        return "add_new";
      } else {
        return "";
      }
    },
  },
  mounted() {
    if (this.type == "create") {
      if (!this.authUser.isAdmin) {
        this.options.tenant = this.authUser.tenant_id;
        this.onTenantChange(this.authUser.tenant_id);
      }
    }
    if (this.type == "edit") {
      this.options = { ...this.item };
      this.options.terminal = this.item.terminal_id
      this.options.nozzles=this.item.nozzles
      this.onTenantChange(this.options.tenant)
    }
    if(this.options.terminal){
    this.$store.dispatch("fuelDispenser/dropDownListFuel",
      { terminal_id: this.options.terminal }
    );
    }
  },
  methods: {
    addNozzles() {
      this.options.nozzles.push({
        nozzle_number: null,
        flow_rate: null,
        // is_active: null,
        operational_status: null,
      });
    },
    deleteRow(index,row) {
      this.options.nozzles.splice(index, 1);
      this.deleted_ids.push(row.id)
    },
    async onMakeChange(v) {
      delete this.options.md_model_id;
      if (v) {
        await this.$store.dispatch("mdModels/list", {
          md_make_id: v ?? false,
        });
      }
    },
    onTerminalChange(v) {
      delete this.options.terminal;
      const params = { terminal_id: v };
      this.$store.dispatch("fuelDispenser/dropDownListFuel", params);
      this.options.terminal = v
    },
    productChange() {
      delete this.options.terminal;
    },
    onTenantChange(v) {
      const params = { tenant_id: v };
      this.$store.dispatch("fuelDispenser/dropDownList", params);
      let paramsIot = {
        tenant_id: v ?? false,
        vehicle_assigned: false,
      }
      if (this.type == "edit") {
        paramsIot = {
          tenant_id: v ?? false,
          vehicle_assigned_to: this.options.iot_device,
        }
      }
      this.$store.dispatch("iotDevices/list", paramsIot);
    },
    saveItem() {
      this.options.status = this.options.operational_status
      this.options.nozzles.forEach(nozzle => {
      delete nozzle.tenant;
      delete nozzle.dispenser;
      this.options.deleted_ids =this.deleted_ids
    });
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      // this.$refs.form.reset();
      this.$emit("onclose");
    },
  },
};
</script>